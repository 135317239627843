import 'bootstrap';

import * as React from 'react';

import * as ReactDOM from 'react-dom/client';

import { MibGateway } from './app.jsx';
import * as config from './config.js';

ReactDOM.createRoot(document.getElementById("app")).render(
    <MibGateway />
);
