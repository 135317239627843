import {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import {
  FaBan,
  FaExternalLinkAlt,
} from 'react-icons/fa';

import * as logo from './assets/logo-blue.png';
import * as spinner from './assets/waiting.gif';

export const MibGateway = ({ }) => {

    return (
        <>
            <Header />
            <MainContent />
        </>
    );

}

const Loader = ({ }) => {

    return (<>
        <Container>
            <Row>
                <Col className='text-center'>
                    <Image style={ { height: "180px" } } src={ spinner } />
                </Col>
            </Row>
        </Container>
    </>);

}

const MainContent = ({ }) => {

    const [view, setView] = useState();
    const [result, setResult] = useState("");
    const [payload, setPayload] = useState({});
    const [apiKey, setApiKey] = useState("(missing)");
    const [output, setOutput] = useState("summary");

    useEffect(() => {
        setView('raw');
        setPayload({
            "transaction": {
                "date": "2024-11-06",
                "guid": "WLI-1613595407",
                "pending_ok": 0,
                "time": "12:00:00"
            },
            "request": { "aia": 1, "test": 1 },
            "quote": { "amount": 150000, "number": "abc123" },
            "person": {
                "id": "123",
                "birth_province_tc": "0",
                "birth_province_code": "",
                "current_province_tc": "0",
                "current_province_code": "",
                "dob": "1947-02-12",
                "first_name": "LAUREN",
                "middle_name": "L",
                "last_name": "SNMMMMTESTCASEBL",
                "occupation": "Computer Service",
                "occupation_class": "",
                "occupation_class_tc": "0",
                "postal_code": "R3C 1P3",
                "gender_tc": "2",
                "gender_code": "Female"
            },
            "output": "summary"
        })
    }, []);

    const onChange = (e) => {

        if (e.target && e.target.id) {
            let newPayload = { ...payload };

            switch (e.target.id) {
                case 'apiKey':
                    setApiKey(e.target.value);
                    break;
                case 'output':
                    setOutput(e.target.value);
                    newPayload["output"] = e.target.value;
                    setPayload(newPayload);
                    break;
                case 'payload':
                    if (JSON.stringify(newPayload) !== JSON.stringify(e.target.value)) {
                        setPayload(newPayload);
                    }
                    break;
            }
        }

    }

    const queryApi = (e) => {


        const url = 'https://i6nngrakcd.execute-api.ca-central-1.amazonaws.com/checks';

        const data = { ...payload };

        setView('waiting');
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                setResult(JSON.stringify(data));
                setView('result');
            })
            .catch(error => {
                setView('error');
            });

    }

    return (
        <Container>
            <Row className='mt-4'>
                <Col className='text-center'>
                    <h1>MIB Gateway <small className='text-muted'>test area</small></h1>
                </Col>
            </Row>
            <Row>
                <Col className="col-4">
                    <Card>
                        <Card.Header>About</Card.Header>
                        <Card.Body>On this page you can send a test request to MIB. The request will be routed through the internal MIB Gateway and the actual response will be displayed here.</Card.Body>
                        <Card.Footer><FaBan /> This interface will not allow non-test requests due to privacy concerns.</Card.Footer>
                    </Card>
                </Col>
                <Col className="col-8">
                    <Card>
                        <Card.Body>
                            { !['error', 'result'].includes(view) &&
                                <Container fluid>
                                    <Row>
                                        <Col className="ps-0 pe-1">
                                            <Form.Group className="mb-3" controlId="apiKey">
                                                <Form.Label>API Key</Form.Label>
                                                <Form.Control onChange={ onChange } value={ apiKey || "" } />&nbsp;
                                            </Form.Group>
                                        </Col>
                                        <Col className="px-0">
                                            <Form.Group className="mb-3" controlId="output">
                                                <Form.Label>Output type</Form.Label>
                                                <Form.Select value={ output || "summary" } onChange={ onChange } aria-label="Default select example">
                                                    <option value="summary">Simplified</option>
                                                    <option value="json">JSON</option>
                                                    <option disabled>Raw XML</option>
                                                </Form.Select>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                </Container>
                            }
                            { 'error' === view &&
                                <Form>
                                    <h5>Request failed.</h5>
                                    <Button onClick={ (e) => setView('form') } variant="primary" type="button">
                                        New request
                                    </Button>
                                </Form>
                            }
                            { 'result' === view &&
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Control value={ result && JSON.stringify(JSON.parse(result), null, 2) || "" } as="textarea" rows={ 8 } />
                                    </Form.Group>
                                    <Button onClick={ (e) => setView('raw') } variant="primary" type="button">
                                        New request
                                    </Button>
                                </Form>
                            }
                            { 'raw' === view &&
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Control onChange={ onChange } value={ payload && JSON.stringify(payload, null, 2) || "" } as="textarea" rows={ 8 } />
                                    </Form.Group>
                                    <Button onClick={ queryApi } variant="primary" type="button">
                                        Send request
                                    </Button>
                                </Form>
                            }

                            { 'waiting' === view &&
                                <Loader />
                            }
                        </Card.Body>
                        <Card.Footer>This web page doesn't allow raw XML output but the API endpoint does.</Card.Footer>
                    </Card>
                </Col>
            </Row>

        </Container>
    );

}

const Header = ({ }) => {

    return (
        <Container fluid>
            <Row className='mt-2 mb-2'>
                <Col>
                    <Image className='broker-logo' src={ logo } />
                </Col>
                <Col className='text-end'>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                            <FaExternalLinkAlt />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item target="_blank" href="https://mib-gateway-api.approve-nonprod.wawanesalife.com/checks">API POST endpoint</Dropdown.Item>
                            <Dropdown.Item target="_blank" href="https://ca-central-1.console.aws.amazon.com/s3/buckets/mib-nonprod?region=ca-central-1&bucketType=general&tab=objects">S3 bucket</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

        </Container>
    );

}